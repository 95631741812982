import { PublicClientApplication } from "@azure/msal-browser";

export const SignIn = () => {
  const msalConfig = {
    auth: {
      clientId: "0d6efffb-1e00-4972-8043-54ef13b6b4b7",
      authority:
        "https://login.microsoftonline.com/0bfaee50-a856-4466-8122-c331879d0dba",
      redirectUri: "http://localhost:3000",
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
  };

  const loginRequest = {
    scopes: ["User.Read", "Group.Read.All"],
  };

  const msalInstance = new PublicClientApplication(msalConfig);
  msalInstance.initialize();

  async function handleCoreAuth(ms_graph_token) {
    const response = await fetch("https://core.impressdesigns.com/sso/entra", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ms_graph_token: ms_graph_token }),
    });

    if (response.status !== 200) {
      console.error(
        "Error fetching token from Core! Status: " + response.status,
      );
      return;
    }

    const data = await response.json();
    return data.access_token;
  }

  const handleLogin = async () => {
    msalInstance
      .loginPopup({
        ...loginRequest,
        redirectUri: "/redirect",
      })
      .then(async (value) => {
        let core_token = await handleCoreAuth(value.accessToken);
        document.cookie = "access-token=" + core_token;
        window.location.href = "/";
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <div class="flex h-screen justify-center items-center">
        <button
          onClick={handleLogin}
          type="button"
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        >
          Sign in
        </button>
      </div>
    </>
  );
};
